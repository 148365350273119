var ViewCurrentBenefitsTileJS = (function () {
    var initiated = false;
    return {
        init: function () {
            if (initiated) {
                return;
            }

            //PRINT THE PAGE
            $(".my-benefits-main-header-print").click(function () {
                window.print();
            });

            function smoothScrollingTo(target) {
                setTimeout(function () {
                    $('.no-rail-layout-row').scroll().scrollTop($(target).offset().top);
                }, 400);
            }

            //WHEN YOU CLICK A LINK, OPEN ALL PANELS AND SMOOTH SCROLL TO MATCHING ID
            $('.circular-menu-item-link').click(function () {
                var collapsedClass = "current-benefit-number-" + this.id.toLowerCase();

                $('.current-benefits-card__info-expand').each(function () {
                    if ($(this).hasClass(collapsedClass)) {
                        $(this).collapse("show");

                    } else {
                        $(this).collapse("hide");
                    }
                });

                smoothScrollingTo(('#main-card-' + this.id));
            });

            //CHECK SIZE OF WINDOW AND DETERMINE IF CARDS OR OPENED OR NOT
            $(document).ready(function () {
                if (typeof window.matchMedia == 'function'
                    && matchMedia('(max-width: 767.98px)').matches) {
                    $('.current-benefits-card__info-expand').collapse("hide");
                } else {
                    $('.current-benefits-card__info-expand').collapse("show");
                }
            });

            initiated = true;
        }
    }
})();