var myInfoTileJS = (function () {
    var initiated = false;
    return {
        init: function () {
            if (initiated) {
                return;
            }

            function smoothScrollingTo(target) {
                setTimeout(function () {
                    $('.no-rail-layout-row').scroll().scrollTop($(target).offset().top);
                }, 400);                
            }

            //WHEN YOU CLICK A LINK, OPEN ALL PANELS AND SMOOTH SCROLL TO MATCHING ID
            $('.circular-menu-item-link').click(function () {
                var collapsedClass = "my-info-card__body-" + $(this).data('target').toLowerCase();

                $('.my-info-card__body').each(function () {
                    if ($(this).hasClass(collapsedClass)) {
                        $(this).collapse("show");

                    } else {
                        $(this).collapse("hide");
                    }
                });

                smoothScrollingTo(('#' + $(this).data('target')));
            });

            $(window).bind('load', function () {
                if ($(this).width() < 767) {
                    $('.my-info-card__body').collapse("hide");
                    $('#PersonalInfoCollapse').collapse("show");
                } else {
                    $('.my-info-card__body').collapse("show");
                }
            });

            initiated = true;
        }
    }
})();