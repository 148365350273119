var formTileJS = (function () {
    var initiated = false;
    return {
        init: function (pageID, partialViewURL, instanceName) {
            if (initiated) {
                return;
            }

            $(document).ready(function () {
                $("#" + instanceName + "FormTypes").change(function () {
                    UpdateLibraryFilter();
                });

                //hide arrows if the area is not scrollable
                updateScrollArrowVisibility();
            });

            //on resize if there is no content to scroll to, hide all the arrows
            $(window).resize(updateScrollArrowVisibility);

            function UpdateLibraryFilter() {
                var categoryType = $("#" + instanceName + "FormTypes").val();
                var participantID = getUrlVars()["participantID"];
                var pageTileID = pageID;

                $.ajax({
                    type: "POST",
                    url: partialViewURL,
                    data: { participantID: participantID, pageTileID: pageTileID, CategoryType: categoryType },
                    success: function (data) {
                        $("#" + instanceName + "formsgrid").html(data);
                        getFormTileUserInput();
                    }
                });
            }

            // Read a page's GET URL variables and return them as an associative array.
            function getUrlVars() {
                var vars = [], hash;
                var hashes = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");
                for (var i = 0; i < hashes.length; i++) {
                    hash = hashes[i].split("=");
                    vars.push(hash[0]);
                    vars[hash[0]] = hash[1];
                }
                return vars;
            }

            function getFormTileUserInput() {
                /*User Search*/
                var userInput = $('#' + instanceName + 'forms-tile-search');
                var topicTitle = $(".form-tile-file-name");

                userInput.keyup(function () {
                    var word = userInput.val();
                    if (word) {
                        for (var i = 0; i < topicTitle.length; i++) {
                            var titleText = (topicTitle[i].innerText.toLowerCase());
                            var title = (topicTitle[i]);
                            if (titleText.indexOf(word.toLowerCase()) >= 0) {
                                $(title).closest(".form-tile-forms-box-wrapper").show();
                            } else {
                                $(title).closest(".form-tile-forms-box-wrapper").hide();
                            }
                        }
                    } else {
                        for (var i = 0; i < topicTitle.length; i++) {
                            var title = (topicTitle[i]);
                            $(title).closest(".form-tile-forms-box-wrapper").show();
                        }
                    }
                });
            }

            getFormTileUserInput();


            /*Truncate Titles*/
            $(".short-text").each(function () {
                var currentTitle = this;
                var title = this.innerText;

                if (title.length > 15) {
                    var shortText = jQuery.trim(title).substring(0, 15).trim(this) + "...";
                    currentTitle.innerText = shortText;
                }

            });

            var featuredFormContainer = document.getElementById('featured-form-wrapper_scroller');


            $('#featured-form-wrapper_scroller').scroll(updateScrollArrowVisibility);

            function updateScrollArrowVisibility() {
                var scrollableAreaOuterWidth = $('#featured-form-wrapper_scroller').outerWidth();
                var scrollWidth = $('#featured-form-wrapper_scroller')[0].scrollWidth;
                var scrollLeft = $('#featured-form-wrapper_scroller').scrollLeft();
                //variance is the amount of a card length that can be hidden and still allow
                //having the arrows show
                var variance = $(".featured-form-card-container").width() * 0.05;

                $("#featured-form-wrapper_scroller-right").toggle(scrollWidth - scrollableAreaOuterWidth - scrollLeft > variance);
                $("#featured-form-wrapper_scroller-left").toggle(scrollLeft > variance);
            }

            $("#featured-form-wrapper_scroller-left").click(function () {
                featuredFormContainer.scrollLeft -= $("#featured-form-wrapper_scroller").find(".featured-form-card-container").width();
            });

            $("#featured-form-wrapper_scroller-right").click(function () {
                featuredFormContainer.scrollLeft += $("#featured-form-wrapper_scroller").find(".featured-form-card-container").width();
            });

            initiated = true;
        }
    }
})();