var faqTileJS = (function () {
    var initialized = false;
    var myInstanceName = null;

    return {
        Init: function (instanceName) {
            if (initialized) {
                return;
            }

            function smoothScrollingTo(target) {
                $('.no-rail-layout-row').scroll().scrollTop($(target).offset().top);
            }

            myInstanceName = instanceName;
            var hashIndex = window.location.href.indexOf("#");
            if (hashIndex > -1) {
                var hash = window.location.href.substring(hashIndex + 1);
                $('#' + hash).toggleClass('d-block');
            }

            $('#' + myInstanceName + '-TopicDropdown').change(function () {
                var id = this.id;
                var scrollToId = $('#' + id).val();

                if (scrollToId == "-1") {
                    return;
                }
                smoothScrollingTo(('#'+ scrollToId));
            });

            $('.circular-menu-item-link').click(function () {
                smoothScrollingTo(('#' + this.id))
            });

            /*User Search*/
            var value = $(".faq-tile-accordionQuestion");
            var topicTitle = $(".faq-tile-topicTitle");
            var userInput = $("#faq-tile-user-search");

            //Filter Through Search
            userInput.keyup(function () {
                var word = userInput[0].value;
                if (word !== '') {
                    for (var i = 0; i < topicTitle.length; i++) {
                        topicTitle[i].style.display = "none";
                    }
                    $(".faq-tile-container").css({ 'margin-top': '0px' });
                    $(".faq-tile-contentWrapper").css({ 'margin-top': '0px' });
                } else {
                    for (var i = 0; i < topicTitle.length; i++) {
                        topicTitle[i].style.display = "block";
                    }
                    $(".faq-tile-container").css({ 'margin-top': '20px' });
                    $(".faq-tile-contentWrapper").css({ 'margin-top': '20px' });
                }
                for (var i = 0; i < value.length; i++) {
                    var title = (value[i]);
                    var titleText = (value[i].innerText.toLowerCase());

                    if (titleText.indexOf(word.toLowerCase()) >= 0) {
                        title.parentElement.style.display = "block";
                    } else {
                        title.parentElement.style.display = "none";
                    }
                }
            });

            //On Click Event to Trigger Opening and Closing of Dropdowns

            $(".faq-tile-accordionQuestion").click(function () {
                $(this).next().toggleClass('d-block');

                if ($(this).children('div').children('i').hasClass("open")) {
                    $(this).children('div').children('i').removeClass("fa-chevron-down");
                    $(this).children('div').children('i').addClass("fa-chevron-up");
                    $(this).children('div').children('i').removeClass("open");
                } else {
                    $(this).children('div').children('i').removeClass("fa-chevron-up");
                    $(this).children('div').children('i').addClass("fa-chevron-down");
                    $(this).children('div').children('i').addClass("open");
                }
            });

            initialized = true;
        }
    }
})();